import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    margin: ({ isSession }: { isSession: boolean }) => !isSession && '0 1rem',
    borderRadius: ({ isSession }: { isSession: boolean }) => !isSession && '16px',
    display: 'flex',
    height: ({ isSession }: { isSession: boolean }) =>
      !isSession ? '6rem' : 'none',
    alignItems: 'center',
    backgroundColor: ({ isSession }: { isSession: boolean }) =>
      isSession ? 'transparent' : '#FFF',
    paddingLeft: ({ isSession }: { isSession: boolean }) =>
      !isSession && '1rem',
    paddingRight: ({ isSession }: { isSession: boolean }) =>
      !isSession && '1rem',
    justifyContent: ({ isSession }: { isSession: boolean }) =>
      isSession ? 'flex-start' : 'space-between',
  },
  toolbarContainer: {
    backgroundColor: '#FFF',
    width: '100%',
    height: '100%',
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  toolbarCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    marginRight: '1rem',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '0px',
  },
  btnIcon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
  },
  button: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    minWidth: '5.625rem',
    minHeight: '40px',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  previewBtn: {
    color: '#2F80ED',
    backgroundColor: 'transparent',
  },
  shareBtn: {
    color: '#2F80ED',
    backgroundColor: 'transparent',
  },
  saveBtn: {
    color: '#FFF',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    '&:hover': {
      backgroundColor: '#1a5bbf',
      border: '1px solid #1a5bbf',
    },
  },
  disabledBtn: {
    color: '#FFF !important',
    backgroundColor: '#D0D5DD',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#D0D5DD',
      border: '1px solid ##D0D5DD',
    },
  },
  disabledShareBtn: {
    color: '#D0D5DD',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      color: '#D0D5DD',
      backgroundColor: '#FFFFFF',
      border: '1px solid ##D0D5DD',
    },
  },
  closeBtn: {
    color: '#475467',
    backgroundColor: 'transparent',
    marginRight: 0,
  },
  plusButton: {
    color: '#FFF',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    borderRadius: '6px',
    padding: '8px 12px',

    '&:hover': {
      backgroundColor: '#1a5bbf',
      border: '1px solid #1a5bbf',
    },
  },
  textIcon: {
    cursor: 'pointer',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.typography.pxToRem(32),
    width: theme.typography.pxToRem(32),
    backgroundColor: '#FFFFFF', 
    '&:hover': {
      backgroundColor: '#E9F5FF', 
    },
    '&:active': {
      backgroundColor: '#CAE8FF', 
    },
  },
  activeTextIcon: {
    backgroundColor: '#CAE8FF', 
    '&:hover': {
      backgroundColor: '#B0D6FF',
    }
  },
  fontsizeIcon :{
    cursor: 'pointer',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.typography.pxToRem(18),
    width: theme.typography.pxToRem(18),
    backgroundColor: '#FFFFFF', 
    '&:hover': {
      backgroundColor: '#E9F5FF', 
    },
    '&:active': {
      backgroundColor: '#CAE8FF', 
    },
  },
  centerPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    borderRight: '1px solid #D0D5DD',
    padding: '4px 12px',
  },
  endPart: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '4px 12px',
  },
  inputSize: {
    height: theme.typography.pxToRem(32),
    width: theme.typography.pxToRem(32),
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    color: '#4F4F4F',
    lineHeight: '20px',
    fontSize: '16px',
    textAlign: 'center',
  },
  arrows: {
    gap: '10px',
  },
  textType: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '10rem',
    height: '2rem',
    padding: '0px 10px 0px 10px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    fontSize: '14px',
    fontFamily: 'Playfair Display',
    fontWeight: 400,
    color: '#475467',
    lineHeight: '21.33px',
  },
  tooltip: {
    borderRadius: '8px',
    backgroundColor: '#101828',
    fontFamily: 'Inter, sans-serif',
    color: '#FFFFFF',
    fontWeight: 600,
    lineHeight: '18px',
    fontSize: '12px',
    textAlign: 'left',
  },
  inputLinkWrapper: {
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '34rem',
    height: '3rem',
    padding: '10px',
    gap: '10px',
    borderRadius: '8px',
  },
  inputLink: {
    width: '35rem',
    height: '1rem',
    padding: '10px 14px',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
  },
  genericListBox: {
    position: 'absolute',
    top: '100%',
    width: theme.typography.pxToRem(178),
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    // gap: '15px',
    zIndex: 10,
    opacity: 1,
  },
  iconBox:{
    width: theme.typography.pxToRem(15),
    marginRight: '8px'
  },
    colorPickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '80%',
    gap: '10px',
    width: theme.typography.pxToRem(215),
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: 10,
    opacity: 1,
  },
  colorSwatch: {
    cursor: 'pointer',
    display: 'inline-block',
    padding: theme.spacing(0.5),
    border: '1px solid #ccc',
    borderRadius: '2px',
    zIndex: 20,
  },
  colorPickerPopover: {
    position: 'absolute',
    zIndex: 20,
    top: 40,
    left: 0,
  },
  predefinedColors: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: 20,
    width: theme.typography.pxToRem(210),
    height: theme.typography.pxToRem(50),
    paddingBottom: '10px',
    gap: '10px'
  
  },
  predefinedColor: {
    borderRadius: '2px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    zIndex: 20,
    width: theme.typography.pxToRem(15),
    height: theme.typography.pxToRem(15),
    '&.active': {
      border: '2px solid #D0D5DD', // Active color highlight
    boxShadow: '0px 0px 2px #6F798B',

    },
  },
  customColorButton: {
    zIndex: 20,
  },
  textButtonColor: {
    fontFamily: 'Inter, sans-serif',  
    fontSize: '14px',                 
    fontWeight: 600,                  
    lineHeight: '16.94px',            
    textAlign: 'right',               
    color: '#147AF3',
    cursor: 'pointer'
  },
  customColorPicker: {
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  

  },
}));

export default useStyles;
