import React from 'react';
import { ReactComponent as DuplicateIcon } from 'assets/templateSettings/duplicateIcon.svg';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Input from 'components/ui/Inputs';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as AssignIcon } from 'assets/AssignClientModalIcon.svg';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { PATIENTS_RELATED } from 'pages/PatientListPage';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  rootAssign: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: '2rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    paddingInline: '2rem',
    boxSizing: 'border-box',
    marginTop: '-0.5rem',
  },
  containerAssign: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#101828',
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#475467',
  },
  buttonBox: {
    height: '4.25rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: '2rem',
  },
  button: {
    height: theme.typography.pxToRem(44),
    paddingInline: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(8),
    textTransform: 'none',
  },
  cancelButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#344054',
    backgroundColor: '#FFFFFF',
    marginRight: '1rem',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#F7F8FA',
    },
  },
  saveButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    '&:hover': {
      backgroundColor: '#0154CB',
    },
  },
}));

function AssignClientModal({
  values,
  setValues,
}: {
  values: any;
  setValues: any;
}) {
  const classes = useStyles();
  const { data, error, loading: isLoading } = useQuery(PATIENTS_RELATED);

  const users = convertObjectsFormat(data?.patientsRelated, 'Clients');
  return (
    <Box className={classes.rootAssign}>
      <Box className={classes.containerAssign}>
        <AssignIcon />
        <Box className={classes.textBox}>
          <Typography className={classes.title}>Assign to client</Typography>
        </Box>
      </Box>
      <Box mt={2} mb={4}>
        <Symptoms
          options={users}
          values={values}
          setValues={setValues}
          hasErrors={false}
        />
      </Box>
    </Box>
  );
}

export default AssignClientModal;
