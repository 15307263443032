import { gql, useMutation, useQuery } from '@apollo/client';

export interface IPatientData {
  conditions: { _id: string; name: string }[];
  birthday: string;
  email: string;
  phone: string;
  firstname: string;
  middlename: string;
  picture?: string;
  lastname: string;
  address: string;
  _id: string;
  isDemo: boolean;
}
const GET_PATIENTS = gql`
  query getPatientLists {
    patients {
      _id
      birthday
      email
      phone
      picture
      firstname
      middlename
      lastname
      address
    }
  }
`;

const DELETE_PATIENT = gql`
  mutation deletePatient($patientId: ID!) {
    deletePatient(patientId: $patientId)
  }
`;
export function usePatientList() {
  const {
    data: PatientsData,
    error,
    loading: PatientsLoading,
    refetch,
  } = useQuery<{
    patients: IPatientData[];
  }>(GET_PATIENTS);

  const [deletePatient, { loading: isDeleteLoading }] =
    useMutation(DELETE_PATIENT);
  return {
    isLoading: PatientsLoading,
    data: PatientsData,
    error,
    refetch,
    deletePatient,
    isDeleteLoading,
  };
}
