import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as AddIcon } from 'assets/patientActivities/addQestion.svg';
import QestionCard from './QuestionCard';
import { IQuestion } from './FormsPage';
import QuestionPreview from './QuestionPreview';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copy.svg';
import TrashIcon from 'assets/TrashIcon';

interface IFormGeneratorProps {
  questions: IQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>;
  question: IQuestion;
  isNotes?: boolean;
  changeQuestion?: any;
  api?: any;
  deleteBlock?: (index: number) => void;
  getBlockIndex?: () => number;
  edit?: boolean;
}

function FormGenerator({
  questions,
  setQuestions,
  isNotes = false,
  changeQuestion,
  api,
  deleteBlock,
  getBlockIndex,
  edit,
}: IFormGeneratorProps): React.ReactElement {
  const classes = useStyles();
  const ref = useRef(null);
  const question = questions[0];

  const [focusedQuestionIndex, setFocusedQuestionIndex] = useState(0);
  const [isClickedBlock, setClickedBlock] = useState<boolean>(
    edit ? false : true
  );
  const [open, setOpen] = useState(true);
  const handleMouseDown = useCallback((event: MouseEvent | null) => {
    if (ref.current && ref.current.contains(event?.target as Node)) {
      setClickedBlock(true);
    }
  }, []);

  const handleMouseUp = useCallback((event: MouseEvent | null) => {
    if (ref.current && !ref.current.contains(event?.target as Node)) {
      setClickedBlock(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [open, isClickedBlock, handleMouseDown, handleMouseUp]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      }
    }, 100);
  }, []);

  const handleClick = () => {
    setClickedBlock(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
    setClickedBlock(false);
  };
  const handleCopyBlock = () => {
    setOpen(true);
    setTimeout(() => {
      setClickedBlock(false);
      addNewQuestion(question?.type, question?.question);
    }, 5);
  };
  const handleUpdateQuestion = (index, question) => {
    setQuestions((_questions) => {
      const newQuestions = [..._questions];
      newQuestions[index] = question;
      return newQuestions;
    });
  };

  const addNewQuestion = (type, question) => {
    const blockType = {
      'Multiple choice': 'Multiple_choice',
      'Single choice': 'Single_choice',
      'Short answer': 'Short_answer',
      'Long answer': 'Long_answer',
      Dropdown: 'DropDown',
      'Linear scale': 'LineaireScale',
      Date: 'Date',
      Email: 'Email',
      Phone: 'Phone',
      Address: 'Address',
      'Date range': 'DateRange',
      Signature: 'Signature',
      list: 'list',
      Orderedlist: 'Orderedlist',
    };
    if (api) {
      api.blocks.insert(blockType[type], {
        questions: [
          {
            question: question,
            type,
            options: ['Option 1', 'Option 2'],
            description: '',
            required: false,
            isDescription: false,
            range: [1, 3],
          },
        ],
      });
    }
  };

  const handleRemoveQuestion = (cardIndex) => {
    const questionLength = questions.length;
    questionLength > 1 &&
      setQuestions((_questions) => {
        const newQuestions = _questions.filter(
          (_, index) => index !== cardIndex
        );
        return newQuestions;
      });
  };

  return (
    <Box className={classes.root}>
      <Box width="100%" className={classes.padding}>
        {isClickedBlock ? (
          <div ref={ref}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={11}
              justifyContent="space-between"
              alignItems="flex-start"
              direction="row"
              onClick={handleClick}
            >
              <Grid item xs={11}>
                <QestionCard
                  handleClose={handleClose}
                  setOpenDP={setOpen}
                  ref={ref}
                  handleClick={handleClick}
                  deleteBlock={deleteBlock}
                  getBlockIndex={getBlockIndex}
                  updateQuestion={handleUpdateQuestion}
                  removeQuestion={handleRemoveQuestion}
                  question={question}
                  index={0}
                  setFocusedQuestionIndex={setFocusedQuestionIndex}
                  isNotes={isNotes}
                  changeQuestion={changeQuestion}
                  addNewQuestion={addNewQuestion}
                />
              </Grid>

              <Grid item xs={1}>
                <Box
                  className={classes.sideBotton}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    className={classes.pointer}
                    onClick={handleCopyBlock}
                    data-cy={`copy-${0}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mx={1}
                    p={1}
                  >
                    <CopyIcon />
                  </Box>

                  <Box
                    className={classes.pointer}
                    onClick={() => {
                      deleteBlock(getBlockIndex());
                      setOpen(true);
                    }}
                    data-cy={`delete-${0}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mx={1}
                    p={1}
                  >
                    <TrashIcon width="24px" height="24px" fill="#EB5757" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="2rem"
            onClick={handleClick}
          >
            <Box flex={1}>
              <QuestionPreview
                isFormGenerator
                question={question}
                index={0}
                setFocusedQuestionIndex={setFocusedQuestionIndex}
                isFastPreview
                ignoreRequired={isNotes}
                editMode={true}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FormGenerator;
