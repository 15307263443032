import React, { ReactElement, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useStyles } from './style';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Password from './TabsContent/Password';
import EmailNotifications from './TabsContent/EmailNotifications';
import PersonnelInformation from './TabsContent/PersonalInformation';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import { Box } from '@material-ui/core';
import { useToast } from 'hooks/useToast';
import Staff from './TabsContent/Staff';
import WorkingHours from './TabsContent/WorkingHours';
import ManageTags from './ManageTags';
import ManageTemplates from './TabsContent/ManageTemplates';
import { useLocation, useHistory } from 'react-router-dom';
import Language from './Language';
import { useTranslation } from 'react-i18next';
import Payment from 'pages/AdminPage/TabsContent/BillingInformation';
import Subscription from './TabsContent/Subscription';
import SideBar from './SideBar';
import { templateType } from 'pages/AppointmentPage/AppointmentPage';
import Payouts from './Payment/Payouts';
import Rates from './Payment/Rates';
import DemoClientPage from './TabsContent/DemoClientPage';
import CalendarSync from './TabsContent/CalendarSync';
import PracticeInformation from './TabsContent/PracticeInformation';
import Demographics from './TabsContent/Demographics';

export enum REDIRECT {
  payment = 'paymentsettings',
  notification = 'Notification',
  workingHours = 'workingHours',
}
const AdminPage = (): ReactElement => {
  const { dataSubscription, loadingSubscription } = useAuth();
  const isSubscribed = dataSubscription?.subscription;
  const history = useHistory();
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess });
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Settings' });
  }, [headerTitleDispatcher]);
  const { doctor } = useAuth();
  const { noUsedFeatures } = doctor;
  const [firstTimeModal, setFirstTimeModal] = useState(true);
  const [value, setValue] = React.useState(0);
  const location = useLocation<{
    tab: number;
    subTab: number;
  }>();
  useEffect(() => {
    if (location.state?.tab) {
      setCustomValue(location.state?.tab);
      setValue(location.state?.subTab);
    }
  }, [location.state]);
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('query');
  const tabSelected = queryParams.get('tab');
  const [customValue, setCustomValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== customValue) {
      setCustomValue(Number(tabSelected));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (customValue !== null && customValue !== undefined) {
        history.push(`/doctor/admin?tab=${customValue}`);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [customValue]);

  useEffect(() => {
    if (redirectStatus === REDIRECT.payment) {
      setCustomValue(5);
      setValue(2);
    }
    if (redirectStatus === REDIRECT.notification) {
      setCustomValue(3);
    }
    if (redirectStatus === REDIRECT.workingHours) {
      setCustomValue(2);
    }
  }, [redirectStatus]);

  const menuData = [
    {
      title: 'YOUR ACCOUNT',
      items: [
        {
          title: 'Contact information',
          content: <PersonnelInformation />,
          disabled: isSubscribed ? false : true,
          index: 0,
        },
        {
          title: 'Demographics',
          content: <Demographics />,
          disabled: false,
          index: 18,
        },
        {
          title: 'Login and security',
          content: <Password />,
          disabled: false,
          index: 1,
        },
        {
          title: 'Regional settings',
          content: <Language />,
          disabled: false,
          index: 9,
        },
        {
          title: 'Notifications',
          content: <EmailNotifications />,
          disabled: isSubscribed ? false : true,
          index: 3,
        },
      ],
    },
    {
      title: 'PRACTICE SETTINGS',
      items: [
        {
          title: 'Practice information',
          content: <PracticeInformation isPracticeSetting />,
          disabled: isSubscribed ? false : true,
          index: 5,
        },
        {
          title: 'Working hours',
          content: <WorkingHours />,
          disabled: isSubscribed ? false : true,
          index: 2,
        },
        {
          title: 'Calendar sync',
          content: <CalendarSync />,
          disabled: isSubscribed ? false : true,
          index: 17,
        },
        {
          title: 'Booking configuration',
          content: <></>,
          disabled: true,
          index: -1,
        },
        {
          title: 'Services',
          content: <Rates />,
          disabled: isSubscribed ? false : true,
          index: 14,
        },
        {
          title: 'Team settings',
          content: <Staff />,
          disabled: isSubscribed ? false : true,
          index: 4,
        },
        {
          title: 'Demo client',
          content: <DemoClientPage />,
          disabled: isSubscribed ? false : true,
          index: 12,
        },
      ],
    },
    {
      title: 'CONFIGURATIONS',
      items: [
        // {
        //   title: 'Notes templates',
        //   content: <ManageTemplates type={templateType.note} />,
        //   disabled: isSubscribed ? false : true,
        //   index: 7,
        // },
        // {
        //   title: 'Form templates',
        //   content: <ManageTemplates type={templateType.form} />,
        //   disabled: isSubscribed ? false : true,
        //   index: 8,
        // },
        {
          title: 'Templates',
          content: <ManageTemplates />,
          disabled: isSubscribed ? false : true,
          index: 7,
        },
        {
          title: 'Tags',
          content: <ManageTags />,
          disabled: isSubscribed ? false : true,
          index: 6,
        },
      ],
    },
    {
      title: 'PAYMENT',
      items: [
        {
          title: 'Subscription',
          content: (
            <Subscription
              data={dataSubscription}
              loading={loadingSubscription}
            />
          ),
          disabled: false,
          index: 15,
        },
        {
          title: 'Payment settings',
          content: <Payment />,
          disabled: false,
          index: 16,
        },
        {
          title: 'Payouts',
          content: <Payouts />,
          disabled: isSubscribed ? false : true,
          index: 13,
        },

        {
          title: 'Billing',
          content: <></>,
          disabled: true,
          index: -1,
        },
      ],
    },
  ];

  return (
    <Box
      style={{
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <SideBar
        menuData={menuData}
        setCustomValue={setCustomValue}
        customValue={customValue}
        loadingSubscription={loadingSubscription}
      />
    </Box>
  );
};
export default AdminPage;

export const GET_CURRENT_USER = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      _id
      firstname
      middlename
      lastname
      email
      country
      phoneNumber
      city
      address
      zipcode
      birthday
      password
      description
      MartialStatus
      gender
      streetadress2
      streetadress1
      provinceState
      emailNotification
      smsNotification
      desktopAlert
      sendNotificationtoConfirm
      youhavenewnotification
      youresentadirectmessage
      someoneaddsyouasaconnection
      whentoescalateemails
      uponneworder
      newmembershipapproval
      memberregistration
      passwordChanged
      language
      isOnboarded
      onboardingStep
    }
  }
`;
export const GET_CREDIT_CARD = gql`
  query userCreditCard($userid: ID!) {
    userCreditCard(userid: $userid) {
      _id
      nameOnCard
      cardNumber
      expirationCard
      defaultCard
      cardZipCode
      securityCode
      FirstName
      MiddleName
      month
      year
      LastName
      Phone
      Email
      StreetAddress1
      StreetAddress2
      City
      ZipCode
      Country
      ProvinceState
      selectedCreditCard
      user {
        firstname
        lastname
        _id
      }
    }
  }
`;
