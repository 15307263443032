import { gql, useMutation } from '@apollo/client';
import { GET_DOCTOR_BY_ID } from './useGetDoctor';

export const TOGGLE_DEMO = gql`
  mutation toggleDemo {
    toggleDemo
  }
`;

export default function useToggleDemo() {
  const [toggleDemo, { loading, error }] = useMutation(TOGGLE_DEMO);

  return { toggleDemo, loading, error };
}
