import React from 'react';
import { useStyles } from '../styles';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditButtons from 'components/EditButtons';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import ScrollBar from 'components/ui/ScrollBar';
import Input from 'components/ui/Inputs';
import Switch from 'components/ui/SwitchNew';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/';
import ErrorIcon from '@material-ui/icons/Error';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import BasicModal from 'components/ui/Modal';
import FormPage from 'pages/FormsPage';
import Text from 'Text';
import Editorjs from 'components/Editorjs';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import { log } from 'config';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import useEditDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditDoctorTemplate';
import { DOCTOR_TEMPLATES_BY_FORM } from 'pages/AdminPage/TabsContent/ManageTemplates';
import { FormProvider } from 'react-hook-form';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import AssignClientModal from 'pages/AdminPage/TabsContent/templatesTabs/AssignClient';
import TemplateSaveModal from './TemplateSaveModal';
import { useSnackbar } from 'hooks/useSnackbar';
import useAssignQuestionaire from 'pages/PatientActivities/Questionaires/useAssignQuestionaire';

const schema = yup.object().shape({
  formName: yup.string().required('Form name is a required field'),
  description: yup.string().required('Description is a required field'),
});

interface IFormTypeProps {
  handleBack: () => void;
  patientId?: string;
  patient?: IPatientData;
  settings?: boolean;
}

function FormType({
  handleBack,
  patientId,
  patient,
  settings = false,
}: IFormTypeProps) {
  const {
    editorRef,
    selectedTemplate,
    formTitle,
    handleChangeText,
    resetBlock,
    editMode,
    templateId,
    togglePreview,
  } = useEditorContext();

  const { doctor } = useAuth();

  const classes = useStyles({ selected: false });
  const history = useHistory();
  const { control, register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = React.useState(false);
  const [forms, setForms] = React.useState(null);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [currentSavedData, setCurrentSavedData] = React.useState(null);
  function handleOpenAssign() {
    setOpenAssign(true);
  }
  function handleOpenSave() {
    setOpenSave(true);
  }
  const [isShare, setIsShare] = React.useState(false);
  function handleShare() {
    setIsShare(true);
  }
  function handleResetShare() {
    setIsShare(false);
  }
  const [openSave, setOpenSave] = React.useState(false);
  function handleCloseSave() {
    setOpenSave(false);
  }
  const methods = useForm();
  const methodsSave = useForm({
    defaultValues: {
      formType: selectedTemplate,
    },
  });
  React.useEffect(() => {
    if (selectedTemplate) {
      methodsSave.reset({
        formType: selectedTemplate,
      });
    }
  }, [methodsSave.reset, selectedTemplate]);
  const [values, setValues] = React.useState<any[]>(
    convertObjectsFormat([], '')
  );
  const { triggerSnack } = useSnackbar();
  const { handleAssignQuestionaire, loading: loadingAssign } =
    useAssignQuestionaire(false);
  async function onAssign(data) {
    if (values.length > 0) {
      data.patients = values.map(({ id }) => id);
      await handleAssignQuestionaire(
        [],
        doctor?._id,
        undefined,
        currentSavedData?.title,
        currentSavedData?.description,
        currentSavedData?.formType,
        currentSavedData?.blocks,
        data.patients
      );
      handleClosePatientModal();
      triggerSnack('Template successfully assigned to clients');
    }
  }
  function handleClosePatientModal() {
    setOpenAssign(false);
  }
  const onSubmit = (data) => {
    setOpen(true);
    setForms({
      formName: data?.formName,
      formDescription: data?.description,
      doctorId: doctor?._id,
      patientId: patient?._id,
      assignText: `Assign this form to ${patient?.firstname} ${patient?.lastname}?`,
      settings,
    });
  };
  const [handleCreateDoctorTemplate, { loading: loadingCreate }] =
    useCreateDoctorTemplate();
  const [handleEditDoctorTemplate, { loading: loadingEdit }] =
    useEditDoctorTemplate();
  async function handlePreview() {
    const { blocks } = await editorRef.current.save();
    const forms = JSON.stringify(blocks);
    togglePreview(true, forms);
  }
  function resetPreview() {
    togglePreview(false, null);
  }
  async function handleSave(data) {
    const { blocks } = await editorRef.current.save();
    const forms = JSON.stringify(blocks);
    log('from handle save', blocks);
    const type = {
      'note-taking': 'note',
      'assessment-form': 'form',
      'intake-form': 'form',
      other: 'other',
    };

    if (!editMode) {
      const result = await handleCreateDoctorTemplate({
        variables: {
          doctorTemplateInput: {
            title: formTitle,
            description: 'description',
            questions: [],
            lastOpenedAt: new Date(),
            type: type[data?.formType],
            formType: data?.formType,
            blocks: forms,
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
      triggerSnack('Template successfully saved');
      setCurrentSavedData(result?.data?.createDoctorTemplate);
    } else {
      const result = await handleEditDoctorTemplate({
        variables: {
          id: templateId,
          doctorTemplateInput: {
            blocks: forms,
            title: formTitle,
            formType: data?.formType,
            type: type[data?.formType],
          },
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
      triggerSnack('Template successfully updated');
      setCurrentSavedData(result.data.createDoctorTemplate);
    }
    if (isShare && data?.formType !== 'note-taking') {
      handleOpenAssign();
    }
    handleResetShare();
    handleCloseSave();

    // the modal should only be closed when doctor click on close this is on purpose of design!
    // handleBack();
  }
  return (
    <Box
      style={{
        height: '100%',
        maxWidth: '100vw',
        maxHeight: '100%',
        minHeight: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(244, 242, 238)',
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box className={classes.reflectionModal} style={{ width: '100vw' }}>
          <Box className={classes.scrollBarForm}>
            <Editorjs
              handleClose={() => {
                handleBack();
                handleChangeText('Untitled template');
                resetBlock();
                resetPreview();
              }}
              handleSave={() => setOpenSave(true)}
              handleShare={handleShare}
              handleOpenSave={handleOpenSave}
              handlePreview={handlePreview}
            />
          </Box>
        </Box>
      </form>
      <FormProvider {...methods}>
        <BasicModal
          open={openAssign}
          onClose={() => {
            handleClosePatientModal();
            handleResetShare();
          }}
          handleClose={() => {
            handleClosePatientModal();
            handleResetShare();
          }}
          isFromModal
          onSubmit={onAssign}
          loading={loadingAssign}
          btnsPadding={'2.5rem'}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '25vw',
                xl: '25vw',
              },
            }}
          >
            <AssignClientModal values={values} setValues={setValues} />
          </Box>
        </BasicModal>
      </FormProvider>
      <FormProvider {...methodsSave}>
        <BasicModal
          open={openSave}
          onClose={() => {
            handleCloseSave();
            handleResetShare();
          }}
          handleClose={() => {
            handleCloseSave();
            handleResetShare();
          }}
          isFromModal
          onSubmit={handleSave}
          loading={loadingCreate || loadingEdit}
          btnsPadding={'2.5rem'}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '28vw',
                xl: '28vw',
              },
            }}
          >
            <TemplateSaveModal formTitle={formTitle} isShare={isShare} />
          </Box>
        </BasicModal>
      </FormProvider>
    </Box>
  );
}

export default FormType;
