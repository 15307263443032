import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    backgroundColor: 'Transparent',
    border: '2px solid rgba(164, 164, 164, 1)',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    backgroundColor: ({ customColor }: { customColor: string }) => customColor,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    border: '2px solid ',
    borderColor: ({ customColor }: { customColor: string }) => customColor,
    '&:before': {
      display: 'block',
      width: '20px',
      height: '20px',
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
});

interface IRadio extends RadioProps {
  customColor: string;
}
export function StyledRadio(props: IRadio) {
  const { customColor } = props;
  const classes = useStyles({ customColor });

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

interface IRadioProps {
  options?: string[];
  nolabel?: boolean;
  row?: boolean;
  name: string;
  color?: string;
  selectedValue?: string;
  setSelectedValue?: React.Dispatch<React.SetStateAction<string>>;
  topLabel?: boolean;
  handleInputChange?: (index: number, value: string) => void;
  index?: number;
  disabled?:boolean
}
export default function CustomizedRadios({
  options,
  nolabel = false,
  row = false,
  topLabel = false,
  name,
  color = 'rgba(31, 97, 220, 1)',
  selectedValue,
  setSelectedValue,
  handleInputChange,
  index,
  disabled=false
}: IRadioProps) {
  const handleChange = (value: string) => {
    handleInputChange && handleInputChange(index, value);
    setSelectedValue && setSelectedValue(value);
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup
        name={name}
        style={{
          display: row && 'flex',
          alignItems: row && 'center',
          flexDirection: row ? 'row' : 'column',
          gap: row && '0px',
          justifyContent: row && 'center',
          flexWrap: row ? 'nowrap' : 'wrap',
        }}
        value={selectedValue}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
      >
        {options &&
          options.map((option) => (
            <FormControlLabel
              value={option}
              control={<StyledRadio customColor={color} />}
              label={option}
              labelPlacement={topLabel ? 'top' : 'end'}
              disabled={disabled}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
}
