import { makeStyles, Theme } from '@material-ui/core';

interface IStyle {
  active?: boolean;
  callRunning?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  overflowCard: {
    overflow: 'hidden',
    overflowY: 'hidden',
  },
  signTitle: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
  },
  signDesc: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#101828',
    fontFamily: 'Inter',
  },
  sectiontitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
    fontFamily: 'Inter',
  },
  rate: {
    fontSize: '16px',
    color: '#475467',
    fontWeight: 700,
    fontFamily: 'Inter',
  },
  fees: {
    fontSize: '16px',
    color: '#475467',
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  endedText: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#3FBF7F',
  },
  endedBox: {
    borderRadius: '10px',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    backgroundColor: 'white',
  },
  bounce: {
    width: 13,
    height: 13,
    backgroundColor: ({ active }: { active: boolean }) =>
      active ? '#3FBF7F' : '#F04438',
    borderRadius: '50%',
    marginRight: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAvailable: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  boxAvailable: {},
  summary: {
    position: 'absolute',
    top: 17,
    right: 17,
  },
  summaryAppt: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#0054B6',
  },
  schedule: {
    padding: '10 8pxpx',
    color: '#344054',
    backgroundColor: 'white',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    textTransform: 'none',
  },
  dropdown: {
    background: 'white',
    padding: '10px',
    width: '100%',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
  },
  rightSection: {
    backgroundColor: '#F9FAFB',
    borderRadius: '12px',
  },
  cardsMargin: {
    margin: theme.spacing(0),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  cardBorder: {
    borderRadius: '10px',
    border: '1px solid #E5E5E5',
  },
  linkCopier: {
    width: '70%',
    [theme.breakpoints.only('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  cardMaxHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  paragraph: {
    color: '#425364',
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  appointmentList: {
    fontSize: '2em',
    fontWeight: 'bold',
    lineHeight: '38px',
    color: '#425364',
    marginBottom: theme.spacing(2),
  },
  conditionTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  email: {
    fontSize: '14px',
    color: '#425364',
  },
  doctorName: {
    fontSize: '18px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    color: 'var(--Neutral-Gray-dark, #52575C);',
    '& *': {
      color: '#425364',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem',
    },
  },
  verticalSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  textColor: {
    color: '#425364',
    '& *': {
      color: '#425364',
    },
  },
  symptomsChip: {
    backgroundColor: '#39B54A',
    borderRadius: '4px',
    color: 'white',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: '34px',
    height: '34px',
    borderRadius: '50%',
    border: ({ active }: IStyle) =>
      active ? '2px solid #66C61C' : '2px solid #F04438',
  },
  doctorHeaderAvatarPicture: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    border: '10px solid #F8FAFB',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  date: {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: 700,
    color: '#101828',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'unset',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    color: '#344054',
    fontFamily: 'Inter, sans-serif',

    '& svg': {
      marginRight: '0.4rem',
    },
  },
  endBox: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  startBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    flexGrow: 1,
    alignItems: 'center',
    height: '62px',
    paddingBlock: '13px',
    boxSizing: 'border-box',
    [theme.breakpoints.only('xs')]: {
      height: '105px',
    },
  },
  doctorHeader: {
    backgroundColor: theme.palette.gray.contrastText,
    position: 'relative',
    height: '65px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      height: '125px',
    },
  },
  boxColumn: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-6),
    },
  },
  shape: {
    backgroundColor: ({ active }: IStyle) =>
      active ? theme.palette.green.main : theme.palette.red.main,
    width: 10,
    height: 10,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
  betweenSpacing: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  background: {
    backgroundColor: '#2DC76D',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
    padding: '1px 8px',
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  startCallAction: {
    marginRight: theme.typography.pxToRem(20),
    border: ({ active }: IStyle) =>
      active ? '1px solid #39C38D' : '1px solid #39C38D',
    color: ({ active }: IStyle) => (active ? 'white' : '#39C38D'),
    backgroundColor: ({ active }: IStyle) =>
      active ? '#2DC76D' : 'transparent',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(20),
    height: '45px',
    paddingRight: theme.typography.pxToRem(20),
    '&:disabled': {
      color: '#2DC76D',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.typography.pxToRem(0),
      marginTop: '-10rem',
      position: 'absolute',
      left: '9rem',
    },
  },
  videoCallIcon: {
    marginRight: theme.typography.pxToRem(10),
    marginLeft: theme.typography.pxToRem(-5),
    display: 'flex',
    alignItems: 'center',
  },
  paid: {
    backgroundColor: '#BEFFD7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#0AAB4D',
  },
  pending: {
    backgroundColor: '#FFD8AD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#AE6209',
  },
  overdue: {
    backgroundColor: '#FFC6C5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#D71300',
  },
  waived: {
    backgroundColor: 'rgba(86, 127, 189, 0.40)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(67),
    height: theme.typography.pxToRem(25),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    letterSpacing: theme.typography.pxToRem(0.1),
    color: '#567FBD',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    backgroundColor: '#F4F3FF',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  btnSummary: {
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(12),
    color: '#425364',
    backgroundColor: 'white',
    fontWeight: 500,
    fontFamily: 'Roboto',
    border: '0.75px solid #BCC6D3',
    borderRadius: theme.typography.pxToRem(5),
    width: '70px',
    height: '30px',
  },
  button: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    minWidth: '5.625rem',
    minHeight: '40px',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  saveBtn: {
    color: '#FFF',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    '&:hover': {
      backgroundColor: '#1a5bbf',
      border: '1px solid #1a5bbf',
    },
  },
  btnIcon: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
  },
  iconButton: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    border: '1px solid #425364',
    backgroundColor: 'transparent',
    color: '#425364',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 500,
  },
  manageButton: {
    fontFamily: 'Inter, sans-serif',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    backgroundColor: '#FFFFFF',
    color: '#344054',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: 600,
  },
  paymentButton: {
    borderRadius: '5px 0px 0px 5px',
    backgroundColor: 'rgba(242, 201, 76, 0.40)',
    height: '100%',
    padding: '6px 8px',
    boxSizing: 'border-box',
    borderRight: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'default',
  },
  paymentText: {
    color: ' var(--Orange, #F2994A)',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  paymentDropDown: {
    backgroundColor: 'rgba(242, 201, 76, 0.40)',
    maxWidth: '27px',
    minWidth: '27px',
    height: '100%',
    boxSizing: 'border-box',
    borderLeft: '0.5px solid #F2994A',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  rejoinBtn: {
    backgroundColor: ({ active }: { active: boolean }) => '#0265DC',
    border: ({ active }: { active: boolean }) => '1px solid #0265DC',
    borderRadius: '8px',
    color: ({ active }: { active: boolean }) => 'white',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    cursor: ({ active }: { active: boolean }) => 'pointer',
    padding: '6px 12px',
  },
  rejointbnhover: {
    '& :hover': {
      backgroundColor: ({ active }: { active: boolean }) => '#0265DC',
    },
  },
  endConsultationButton: {
    borderRadius: '8px',
    backgroundColor: '#F04438',
    border: '1px solid #F04438',
    height: '100%',
    padding: '6px 12px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  consultationButton: {
    borderRadius: '8px',
    backgroundColor: ({ active, callRunning }: IStyle) =>
      !callRunning ? '#0265DC' : !callRunning ? '#F2F4F7' : '#F04438',
    border: ({ active, callRunning }: IStyle) =>
      !callRunning
        ? '1px solid #0265DC'
        : !callRunning
        ? '1px solid #EAECF0'
        : '1px solid #F04438',
    height: '100%',
    padding: '6px 12px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: ({ active }: IStyle) => (active ? 'pointer' : 'default'),
  },
  rootRadio: {
    maxWidth: '22px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  modal: {
    paddingInline: '3rem',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  modalPay: {
    paddingInline: '3rem',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '30vw',
  },
  endCall: {
    color: 'white',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '5px',
  },
  consultationText: {
    color: ({ active, callRunning }: IStyle) =>
      !callRunning ? 'white' : !active && !callRunning ? '#98A2B3' : 'white',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '5px',
  },
  consultationDropDown: {
    border: '1px solid var(--Prinicpal-Green, #39C38D)',
    backgroundColor: ({ active }: IStyle) =>
      active ? 'var(--Prinicpal-Green, #39C38D)' : 'transparent',
    paddingInline: '8px',
    height: '100%',
    boxSizing: 'border-box',
    borderLeft: ({ active }: IStyle) =>
      active ? '0.5px solid white' : '#39C38D',
    borderRadius: '0px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  consultationHistory: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // boxSizing: 'border-box',
    backgroundColor: '#F9FAFB',
    borderRadius: '12px',
    marginTop: '16px',
  },
  historyTitleBox: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  historyTitle: {
    color: '#101828',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '16px',
    fontFamily: 'Inter',
  },
  historyTableBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
  },
  consultation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // width: '100%',
    backgroundColor: 'var(--White, #FFF)',
    position: 'relative',

    padding: '17px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },
  consultationDate: {
    color: '#101828',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  consultationType: {
    color: '#0C111D',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  notesTitle: {
    color: '#101828',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  paidPayment: {
    height: '100%',
    paddingInline: '8px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(111, 207, 151, 0.40)',
    color: 'var(--Green-1, #219653)',
    textAlign: 'center',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  waivedPayment: {
    whiteSpace: 'nowrap',
    height: '100%',
    paddingInline: '8px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(86, 127, 189, 0.30)',
    color: ' var(--blue-texte, #567FBD)',
    textAlign: 'center',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  reviewSection: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
  },
  refundSection: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    backgroundColor: '#FFF',
  },
  refundTitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  refundDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  refundAmount: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  textRow: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  paidChip: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    borderRadius: '16px',
    backgroundColor: 'white',
    padding: '4px 8px',
    textTransform: 'capitalize',
  },
  desc: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    color: '#101828',
  },
  bottomCardEnd: {
    backgroundColor: 'black',
    borderTop: '1px solid #147AF37D',
    padding: '8px 16px',
  },
  bottomCardWrapper: {
    backgroundColor: ({ active, callRunning }: IStyle) =>
      !callRunning ? 'white' : 'black',
    borderTop: '1px solid #147AF37D',
    padding: '8px 16px',
  },
  backToSessions: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginBottom: '12px',
    textDecoration: 'none',
  },
  backToSessionsText: {
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: '#0054B6',
    textDecoration: 'underline',
  },
}));

export default useStyles;
