import { gql } from '@apollo/client';

export const GET_DOCTOR = gql`
  query getDoctor($DoctorId: ID!) {
    doctor(doctorId: $DoctorId) {
      _id
      firstname
      signature {
        picture
        name
        isGenerated
        index
        updatedAt
      }
      providerSettings {
        specialty
        npi
        licenseNumber
        taxonomy
      }
      organizationSettings {
        npiOrg
        licenseNumberOrg
        taxonomyOrg
        DPS
      }
      lastname
      middlename
      phone
      email
      picture
      address
      city
      zipcode
      country
      suite
      about
      birthday
      gender
      noUsedFeatures
      username
      state
      specialty
      MartialStatus
      experience
      patientGroup
      languages
      focusArea
      pronouns
      raceAndEthnicity
      showBirthday
      showPhone
      showEmail
      showAdress
      supportVideo
      displayName
      certifications {
        certificationName
        yearOfDegree
        certificationLocation
        educationLevel
      }
      noUsedFeatures
    }
    userData @client {
      user {
        doctorId
      }
    }
  }
`;

export const EDIT_DOCTOR = gql`
  mutation editDoctor(
    $doctorID: ID!
    $DoctorInput: DoctorEditInput!
    $picture: Upload
  ) {
    editDoctor(
      doctorID: $doctorID
      DoctorInput: $DoctorInput
      picture: $picture
    )
  }
`;
