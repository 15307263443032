import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ReactComponent as PreviewIcon } from 'assets/form/toolbar/preview.svg';
import { ReactComponent as ShareIcon } from 'assets/form/toolbar/share.svg';
import { ReactComponent as DisabledShareIcon } from 'assets/form/toolbar/disabledShare.svg';
import { ReactComponent as SaveIcon } from 'assets/form/toolbar/save.svg';
import { ReactComponent as PlusIcon } from 'assets/form/toolbar/plus.svg';
import { ReactComponent as FontFamilyIcon } from 'assets/form/toolbar/fontFamily.svg';
import clsx from 'clsx';
import EditableText from './EditableText';
import { ReactComponent as PrevIcon } from 'assets/form/toolbar/reverse-left.svg';
import { ReactComponent as NextIcon } from 'assets/form/toolbar/reverse-right.svg';
import { ReactComponent as MinusTextIcon } from 'assets/form/toolbar/minus-text.svg';
import { ReactComponent as PlusTextIcon } from 'assets/form/toolbar/plus-text.svg';
import { ReactComponent as AlignmentSymbol } from 'assets/form/toolbar/alignment-symbol.svg';
import { ReactComponent as TextColor } from 'assets/form/toolbar/text-color.svg';
import { ReactComponent as HighlightSymbol } from 'assets/form/toolbar/highlight.svg';
import { ReactComponent as LinkSymbol } from 'assets/form/toolbar/link-icon.svg';
import { ReactComponent as Italic } from 'assets/form/toolbar/italic-icon.svg';
import { ReactComponent as NotClickedBold } from 'assets/form/toolbar/not-clicked-bold.svg';
import { useEditorContext } from '../EditorContextProvider';
import { ReactComponent as Underline } from 'assets/form/toolbar/underline-icon.svg';
import { ReactComponent as LeftAlign } from 'assets/form/toolbar/left-align.svg';
import { ReactComponent as CenterAlign } from 'assets/form/toolbar/center-align.svg';
import { ReactComponent as RightAlign } from 'assets/form/toolbar/right-align.svg';
import { ReactComponent as Justify } from 'assets/form/toolbar/align-justify.svg';
import { ReactComponent as IncreaseIndent } from 'assets/form/toolbar/right-intend.svg';
import { ReactComponent as DecreaseIndent } from 'assets/form/toolbar/left-intend.svg';
import { ReactComponent as QuoteIcon } from 'assets/form/toolbar/quote.svg';
import { ReactComponent as Heading1 } from 'assets/form/toolbar/heading1.svg';
import { ReactComponent as Heading2 } from 'assets/form/toolbar/heading2.svg';
import { ReactComponent as Heading3 } from 'assets/form/toolbar/heading3.svg';
import { ReactComponent as Paragraph } from 'assets/form/toolbar/parag.svg';
import { ReactComponent as EditIconEditor } from 'assets/EditIconEditor.svg';

import { ReactComponent as TableIcon } from 'assets/form/toolbar/table.svg';
import { ReactComponent as ImageIcon } from 'assets/form/toolbar/image.svg';
import { ReactComponent as HorizontalLineIcon } from 'assets/form/toolbar/horizontal-line.svg';
import { ReactComponent as PageBreakIcon } from 'assets/form/toolbar/page-break.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/form/toolbar/multiple-choice.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/form/toolbar/single-choice.svg';
import { ReactComponent as DropdownIcon } from 'assets/form/toolbar/dropdown.svg';
import { ReactComponent as DateIcon } from 'assets/form/toolbar/date.svg';
import { ReactComponent as DateRangeIcon } from 'assets/form/toolbar/date-range.svg';
import { ReactComponent as ShortTextIcon } from 'assets/form/toolbar/short-text.svg';
import { ReactComponent as LongTextIcon } from 'assets/form/toolbar/long-text.svg';
import { ReactComponent as SignatureIcon } from 'assets/form/toolbar/signature.svg';
import { ReactComponent as AddressIcon } from 'assets/form/toolbar/address.svg';
import { ReactComponent as EmailIcon } from 'assets/form/toolbar/email.svg';
import { ReactComponent as PhoneNumberIcon } from 'assets/form/toolbar/phone.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/form/toolbar/linear-scale.svg';
import { ReactComponent as Valid } from 'assets/form/toolbar/valid.svg';
import { ReactComponent as NotValid } from 'assets/form/toolbar/not-valid.svg';

import GenericList from './List';
import { addstyle, changeFont, changeAlignment } from './Utils';
import { useSnackbar } from 'hooks/useSnackbar';
import { handleChangeColor, pluginType } from './Utils';
import ColorPicker from './ColorPicker';

export interface IListOption {
  name: string;
  icon?: React.ReactNode;
  blockName?: string;
  level?: number;
  alignment?: string;
  fontFamily?: string;
}
interface Props {
  handleClose?: () => void;
  handleSave?: () => void;
  isSession?: boolean;
  handleShare?: () => void;
  handleOpenSave?: () => void;
  handlePreview?: () => void;
  isPreview?: boolean;
}
export const alignOptions: IListOption[] = [
  { name: 'Left align', icon: <LeftAlign />, alignment: 'left' },
  { name: 'Center align', icon: <CenterAlign />, alignment: 'center' },
  { name: 'Right align', icon: <RightAlign />, alignment: 'right' },
  { name: 'Justify', icon: <Justify />, alignment: 'justify' },
];
export const CustomToolbar = ({
  handleClose,
  handleSave,
  isSession = false,
  handleShare,
  handleOpenSave,
  handlePreview,
  isPreview,
}: Props) => {
  const classes = useStyles({ isSession });
  const theme = useTheme();
  const { triggerSnack } = useSnackbar();

  const isMedium = useMediaQuery(theme.breakpoints.down(1470));
  const {
    selectedTemplate,
    addBlock,
    editorRef,
    startPosition,
    endPosition,
    undoRef,
    togglePreview,
    blocks,
    activeStyle,
    setActiveStyle,
    blockId,
  } = useEditorContext();

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openList, setOpenList] = useState(null);
  const [isUseCustom, setIsUseCustom] = useState(false);
  const [link, setLink] = useState('');
  const [editorStyle, setEditorStyle] = useState({
    currentAlignment: alignOptions[0].alignment,
    currentFontSize: activeStyle?.fontSize || 15,
  });

  const fontSize = useMemo(() => {
    return activeStyle.fontSize;
  }, [activeStyle.fontSize]);

  useEffect(() => {
    setEditorStyle((prevStyle) => ({
      ...prevStyle,
      currentFontSize: fontSize,
    }));
  }, [fontSize]);

  const increaseFontSize = useCallback(() => {
    const newSize = editorStyle.currentFontSize + 1;
    setEditorStyle({ ...editorStyle, currentFontSize: newSize });
    changeFont(startPosition, endPosition, editorRef, `${newSize}px`, blockId);
  }, [blockId, editorStyle, startPosition, endPosition, editorRef]);

  const decreaseFontSize = useCallback(() => {
    if (editorStyle.currentFontSize > 1) {
      const newSize = editorStyle.currentFontSize - 1;
      setEditorStyle({ ...editorStyle, currentFontSize: newSize });
      changeFont(
        startPosition,
        endPosition,
        editorRef,
        `${newSize}px`,
        blockId
      );
    }
  }, [blockId, editorRef, editorStyle, endPosition, startPosition]);

  const handleTextColorChange = (newColor) => {
    handleChangeColor(
      startPosition,
      endPosition,
      editorRef,
      newColor,
      blockId,
      'texColor'
    );
  };
  const handleHighlightColorChange = (newColor) => {
    handleChangeColor(
      startPosition,
      endPosition,
      editorRef,
      newColor,
      blockId,
      'highlightColor'
    );
  };
  const handleAlignmentChange = () => {
    const currentIndex = alignOptions.findIndex(
      (option) => option.alignment === editorStyle.currentAlignment
    );
    //allows the code to cycle through the alignment options in a circular manner
    const nextIndex = (currentIndex + 1) % alignOptions.length;
    setEditorStyle({
      ...editorStyle,
      currentAlignment: alignOptions[nextIndex].alignment,
    });
    changeAlignment(editorRef, alignOptions[nextIndex].alignment);
  };

  const currentIcon = alignOptions.find(
    (option) => option.alignment === editorStyle.currentAlignment
  ).icon;

  const handleSaveTemplate = () => {
    handleSave();
    setActiveStyle({
      bold: false,
      italic: false,
      underline: false,
      fontSize: 15,
    });
    triggerSnack('Template saved successfully');
  };
  const addLinkToEditor = () => {
    addBlock('linkTool');
    setOpenList(null);
    setLink('');
  };
  const toggleListVisibilityList = useCallback((listName) => {
    setOpenList((prev) => (prev === listName ? null : listName));
    setIsUseCustom(false);
  }, []);

  const handlCloseToolbar = () => {
    handleClose();
    setActiveStyle({
      bold: false,
      italic: false,
      underline: false,
      fontSize: 15,
    });
  };

  const fontFamilies = [
    { name: 'Roboto', fontFamily: 'Roboto, sans-serif' },
    { name: 'Open Sans', fontFamily: 'Open Sans, sans-serif' },
    { name: 'Lato', fontFamily: 'Lato, sans-serif' },
    { name: 'Montserrat', fontFamily: 'Montserrat, sans-serif' },
    { name: 'Noto Sans', fontFamily: 'Noto Sans, sans-serif' },
    { name: 'Raleway', fontFamily: 'Raleway, sans-serif' },
    { name: 'Source Sans', fontFamily: 'Source Sans Pro, sans-serif' },
    { name: 'PT Sans', fontFamily: 'PT Sans, sans-serif' },
    { name: 'Merriweather', fontFamily: 'Merriweather, serif' },
    { name: 'Oswald', fontFamily: 'Oswald, sans-serif' },
    { name: 'Poppins', fontFamily: 'Poppins, sans-serif' },
    { name: 'Nunito', fontFamily: 'Nunito, sans-serif' },
    { name: 'Quicksand', fontFamily: 'Quicksand, sans-serif' },
  ];

  const insertOptions: IListOption[] = [
    {
      name: 'Multiple choice',
      icon: <MultipleChoiceIcon />,
      blockName: 'Multiple_choice',
    },
    {
      name: 'Single choice',
      icon: <SingleChoiceIcon />,
      blockName: 'Single_choice',
    },
    {
      name: 'Linear scale',
      icon: <LinearScaleIcon />,
      blockName: 'LineaireScale',
    },
    { name: 'Dropdown', icon: <DropdownIcon />, blockName: 'DropDown' },
    { name: 'Date', icon: <DateIcon />, blockName: 'Date' },
    { name: 'Date range', icon: <DateRangeIcon />, blockName: 'DateRange' },
    { name: 'Short text', icon: <ShortTextIcon />, blockName: 'Short_answer' },
    { name: 'Long text', icon: <LongTextIcon />, blockName: 'Long_answer' },
    { name: 'Email', icon: <EmailIcon />, blockName: 'Email' },
    { name: 'Phone', icon: <PhoneNumberIcon />, blockName: 'Phone' },
    { name: 'Address', icon: <AddressIcon />, blockName: 'Address' },
    { name: 'Signature', icon: <SignatureIcon />, blockName: 'Signature' },
  ];

  const handleUndo = () => {
    if (undoRef.current) undoRef.current.undo();
  };

  const handleRedo = () => {
    if (undoRef.current) undoRef.current.redo();
  };

  return (
    <div className={classes.toolbar}>
      {!isSession && <EditableText />}
      {!isPreview && (
        <div className={classes.toolbarCenter} style={{ position: 'relative' }}>
          {!isSession && (
            <Button
              className={clsx(classes.button, classes.plusButton)}
              onClick={() => toggleListVisibilityList(pluginType.insertOptions)}
            >
              <PlusIcon className={classes.btnIcon} />
              Insert
            </Button>
          )}

          {openList === pluginType.insertOptions && (
            <GenericList
              options={insertOptions}
              toggleListVisibilityList={toggleListVisibilityList}
              selectedIndex={selectedIndex}
            />
          )}
          <div className={clsx(classes.centerPart, classes.arrows)}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Undo"
              placement="top-start"
            >
              <Box className={classes.textIcon} onClick={handleUndo}>
                <PrevIcon />
              </Box>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Redo"
              placement="top-start"
            >
              <Box className={classes.textIcon} onClick={handleRedo}>
                <NextIcon />
              </Box>
            </Tooltip>
          </div>
          <div className={classes.centerPart}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Bold"
              placement="top-start"
            >
              <Box
                className={`${classes.textIcon} ${
                  activeStyle && activeStyle?.bold ? classes.activeTextIcon : ''
                }`}
                onClick={() => {
                  addstyle(startPosition, endPosition, editorRef, 'b', blockId);
                  setActiveStyle({ ...activeStyle, bold: !activeStyle.bold });
                }}
              >
                <NotClickedBold />
              </Box>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Italic"
              placement="top-start"
            >
              <Italic
                className={`${classes.textIcon} ${
                  activeStyle && activeStyle?.italic
                    ? classes.activeTextIcon
                    : ''
                }`}
                onClick={() => {
                  addstyle(startPosition, endPosition, editorRef, 'i', blockId);
                  setActiveStyle({
                    ...activeStyle,
                    italic: !activeStyle.italic,
                  });
                }}
              />
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Underline"
              placement="top-start"
            >
              <Underline
                className={`${classes.textIcon} ${
                  activeStyle && activeStyle?.underline
                    ? classes.activeTextIcon
                    : ''
                }`}
                onClick={() => {
                  addstyle(startPosition, endPosition, editorRef, 'u', blockId);
                  setActiveStyle({
                    ...activeStyle,
                    underline: !activeStyle.underline,
                  });
                }}
              />
            </Tooltip>
          </div>
          <div className={classes.centerPart}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Decrease size"
              placement="top-start"
            >
              <Box className={classes.fontsizeIcon} onClick={decreaseFontSize}>
                <MinusTextIcon />
              </Box>
            </Tooltip>
            <input
              type="text"
              value={editorStyle.currentFontSize}
              readOnly
              className={classes.inputSize}
            />
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Increase size"
              placement="top-start"
            >
              <Box className={classes.fontsizeIcon} onClick={increaseFontSize}>
                <PlusTextIcon />
              </Box>
            </Tooltip>
          </div>
          <div className={classes.centerPart} style={{ position: 'relative' }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Font"
              placement="top-start"
              onClick={() => toggleListVisibilityList(pluginType.fontFamilies)}
            >
              <FontFamilyIcon />
            </Tooltip>

            {openList === pluginType.fontFamilies && (
              <GenericList
                options={fontFamilies}
                toggleListVisibilityList={toggleListVisibilityList}
                selectedIndex={selectedIndex}
              />
            )}
          </div>
          <div className={classes.centerPart} style={{ position: 'relative' }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Text alignment"
              placement="top-start"
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={handleAlignmentChange}
              >
                {currentIcon}
              </div>
            </Tooltip>
          </div>
          <div className={classes.centerPart}>
            <div>
              <button
                onClick={() =>
                  toggleListVisibilityList(pluginType.textColorPicker)
                }
                style={{
                  cursor: 'pointer',
                  border: 'none',
                  background: 'transparent',
                }}
              >
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Text color"
                  placement="top-start"
                >
                  <TextColor className={classes.textIcon} />
                </Tooltip>
              </button>

              <ColorPicker
                open={openList === pluginType.textColorPicker}
                onClose={() => toggleListVisibilityList(null)}
                onColorChange={handleTextColorChange}
                isUseCustom={isUseCustom}
                setIsUseCustom={setIsUseCustom}
                toggleListVisibilityList={toggleListVisibilityList}
              />
            </div>
            <div>
              <button
                onClick={() =>
                  toggleListVisibilityList(pluginType.highlightColorPicker)
                }
                style={{
                  cursor: 'pointer',
                  border: 'none',
                  background: 'transparent',
                }}
              >
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Highlight color"
                  placement="top-start"
                >
                  <HighlightSymbol className={classes.textIcon} />
                </Tooltip>
              </button>

              <ColorPicker
                open={openList === pluginType.highlightColorPicker}
                onClose={() => toggleListVisibilityList(null)}
                onColorChange={handleHighlightColorChange}
                highlight
                isUseCustom={isUseCustom}
                setIsUseCustom={setIsUseCustom}
                toggleListVisibilityList={toggleListVisibilityList}
              />
            </div>
          </div>
          <div className={classes.endPart} style={{ position: 'relative' }}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="Insert link"
              placement="top-start"
            >
              <LinkSymbol
                className={classes.textIcon}
                onClick={() => toggleListVisibilityList('link')}
              />
            </Tooltip>
            {openList == 'link' && (
              <Box
                style={{
                  position: 'absolute',
                  top: '30px',
                  left: '0',
                  zIndex: 40,
                }}
              >
                <List>
                  <ListItem className={classes.inputLinkWrapper}>
                    <input
                      type="text"
                      placeholder="https://"
                      onChange={(e) => setLink(e.target.value)}
                      className={classes.inputLink}
                    />
                    <ListItemIcon
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Valid onClick={addLinkToEditor} />
                      <NotValid onClick={() => setOpenList(null)} />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Box>
            )}
          </div>
        </div>
      )}
      {!isSession && (
        <Box className={classes.buttons}>
          {!isMedium && (
            <>
              <Button
                className={clsx(classes.button, classes.previewBtn)}
                onClick={async () => {
                  if (isPreview) {
                    togglePreview(false, blocks);
                  } else {
                    handlePreview();
                  }
                }}
              >
                {isPreview ? (
                  <EditIconEditor className={classes.btnIcon} />
                ) : (
                  <PreviewIcon className={classes.btnIcon} />
                )}
                {isPreview ? 'Edit' : 'Preview'}
              </Button>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title="Select a location to save and share your template"
                placement="bottom-end"
              >
                <div>
                  <Button
                    className={clsx(
                      classes.button,
                      selectedTemplate
                        ? classes.shareBtn
                        : classes.disabledShareBtn
                    )}
                    disabled={!selectedTemplate}
                    onClick={() => {
                      handleShare();
                      handleOpenSave();
                    }}
                  >
                    {!selectedTemplate ? (
                      <DisabledShareIcon className={classes.btnIcon} />
                    ) : (
                      <ShareIcon className={classes.btnIcon} />
                    )}
                    Assign
                  </Button>
                </div>
              </Tooltip>
            </>
          )}

          {/* <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Select a location to save your template"
            placement="bottom-end"
          > */}
          <div>
            <Button
              className={clsx(
                classes.button,
                selectedTemplate ? classes.saveBtn : classes.disabledBtn
              )}
              data-cy="submit"
              type="submit"
              onClick={handleSaveTemplate}
              disabled={!selectedTemplate}
            >
              <SaveIcon className={classes.btnIcon} />
              Save
            </Button>
          </div>
          {/* </Tooltip> */}
          <Button
            className={clsx(classes.button, classes.closeBtn)}
            onClick={handlCloseToolbar}
          >
            Close
          </Button>
        </Box>
      )}
    </div>
  );
};
