import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useCallback,
} from 'react';

import EditorJS from '@editorjs/editorjs';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Header from 'editorjs-header-with-alignment';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Alert from 'editorjs-alert';
import ToggleBlock from 'editorjs-toggle-block';
import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';
import ColorPlugin from 'editorjs-text-color-plugin';
import DragDrop from 'editorjs-drag-drop';
import ImageTool from '@editorjs/image';
import Undo from 'editorjs-undo';
import HorizantaleLine from './nodes/HorizantaleLine';
import MultipleChoice from './nodes/MultiChoice';
import SingleChoice from './nodes/SingleChoice';
import ShortAnswer from './nodes/ShortText';
import LongAnswer from './nodes/LongText';
import DropDown from './nodes/DropDown';
import LineaireScale from './nodes/LineaireScale';
import Date from './nodes/Date';
import Table from '@editorjs/table';
import Email from './nodes/Email';
import Phone from './nodes/Phone';
import Address from './nodes/Address';
import DateRange from './nodes/DateRange';
import Signature from './nodes/Signature';
import Orderedlist from './nodes/OrderedList';
import UnorderedList from './nodes/UnorderedList';
import MultiResponse from './nodes/Responses/MultipleChoice';
import SingleResponse from './nodes/Responses/SingleChoice';
import LineaireResponse from './nodes/Responses/LineaireResponse';
import DropDownResponse from './nodes/Responses/DropDown';
import DateResponse from './nodes/Responses/DateResponse';
import DateRangeResponse from './nodes/Responses/DateRangeResponse';
import ShortResponse from './nodes/Responses/ShortResponse';
import LongResponse from './nodes/Responses/LongResponse';
import EmailResponse from './nodes/Responses/EmailRespomse';
import PhoneResponse from './nodes/Responses/PhoneResponse';
import AdressResponse from './nodes/Responses/Adress';
import SignatureResponse from './nodes/Responses/Signature';

import { reducer } from './ContextReducer';
import {
  SET_BLOCKS,
  TOGGLE_SIGNATURE,
  SET_TITLE,
  EDIT_MODE,
  RESET_BLOCK,
  SELECTED_TEMPLATE,
  SET_TEMPLATE_ID,
  TOGGLE_PREVIEW,
  SET_ACTIVE_STYLE,
  SET_SELECTED_ELEMENT,
  SET_BLOCK_ID,
} from './actions';
import { selectionEvent } from './Toolbar/Utils';

export const EditorContext = createContext<any>(null);

export enum templateEnum {
  noteTaking = 'note-taking',
  assessmentForm = 'assessment-form',
  intakeForm = 'intake-form',
  other = 'other',
}

export default function EditorContextProvider({ children }) {
  const editorRef = useRef<any>();
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const undoRef = useRef(null);
  const [state, dispatch] = React.useReducer(reducer, {
    blocks: null,
    open: false,
    formTitle: 'Untitled template',
    editMode: false,
    selectedTemplate: templateEnum.assessmentForm,
    templateId: null,
    isPreview: false,
    selectedElement: null,
    blockId: null,
    activeStyle: {
      bold: false,
      italic: false,
      underline: false,
      fontSize: 15,
    },
  });
  function togglePreview(payload, blocks) {
    dispatch({ type: SET_BLOCKS, payload: blocks });
    dispatch({ type: TOGGLE_PREVIEW, payload });
    if (payload) {
      setEdit(true);
    }
  }

  const setSelectedElement = (payload) => {
    dispatch({ type: SET_SELECTED_ELEMENT, payload });
  };

  const setActiveStyle = (payload) => {
    dispatch({ type: SET_ACTIVE_STYLE, payload });
  };
  function setSelectedTemplate(payload) {
    dispatch({ type: SELECTED_TEMPLATE, payload });
  }
  const handleSelectedTemplate = useCallback((templateValue: string) => {
    setSelectedTemplate(templateValue);
  }, []);
  const handleCloseTemplate = useCallback(() => {
    setSelectedTemplate(null);
  }, []);
  const setBlockId = (payload) => {
    dispatch({ type: SET_BLOCK_ID, payload });
  };
  const addBlock = (blockName: string, level?: number) => {
    const editor = editorRef.current;
    if (editor) {
      const totalBlocks = editor.blocks.getBlocksCount();
      editor.blocks.insert(blockName, level && { level }, {}, totalBlocks);
    }
    setEdit(false);
  };
  const clearBlock = () => {
    const editor = editorRef.current;
    if (editor) {
      editor.blocks.clear();
    }
  };

  function setBlocks(blocks, payload, id, editable = false) {
    dispatch({ type: EDIT_MODE, payload });
    dispatch({ type: SET_BLOCKS, payload: blocks });
    dispatch({ type: SET_TEMPLATE_ID, payload: id });
    setEdit(editable);
  }
  function toggleSignature(payload) {
    dispatch({ type: TOGGLE_SIGNATURE, payload });
  }
  function handleChangeText(payload) {
    dispatch({ type: SET_TITLE, payload });
  }
  function toggleEditMode(payload) {
    dispatch({ type: EDIT_MODE, payload });
  }
  function resetBlock() {
    dispatch({ type: RESET_BLOCK });
  }
  const [edit, setEdit] = React.useState(false);
  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editorjs',
      placeholder: 'Type here',
      autofocus: true,
      onReady: () => {
        try {
          new DragDrop(editor);
          const editorContainer = document.getElementById('editorjs');
          if (editorContainer) {
            editorContainer.addEventListener('mouseup', (event) => {
              selectionEvent(
                event,
                setSelectedElement,
                setStartPosition,
                setEndPosition,
                setActiveStyle,
                setBlockId
              );
            });
          }

          const undo = new Undo({ editor });
          undoRef.current = undo;
        } catch (error) {
          console.error('Error during editor initialization:', error);
        }
      },
      data: {
        time: 1724934371391,
        version: '2.30.5',
        blocks: JSON.parse(state?.blocks),
      },
      tools: {
        HorizantaleLine: HorizantaleLine,
        Multiple_choice: {
          class: MultipleChoice,
          config: {
            edit,
          },
        },
        Single_choice: {
          class: SingleChoice,
          config: {
            edit,
          },
        },
        Short_answer: {
          class: ShortAnswer,
          config: {
            edit,
          },
        },
        Long_answer: {
          class: LongAnswer,
          config: {
            edit,
          },
        },
        DropDown: {
          class: DropDown,
          config: {
            edit,
          },
        },
        LineaireScale: {
          class: LineaireScale,
          config: {
            edit,
          },
        },
        Email: {
          class: Email,
          config: {
            edit,
          },
        },
        Phone: {
          class: Phone,
          config: {
            edit,
          },
        },
        Address: {
          class: Address,
          config: {
            edit,
          },
        },
        Date: {
          class: Date,
          config: {
            edit,
          },
        },
        DateRange: {
          class: DateRange,
          config: {
            edit,
          },
        },
        Signature: {
          class: Signature,
          config: {
            edit,
          },
        },
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            type: 'text',
          },
        },
        table: {
          class: Table as any,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        list: {
          class: UnorderedList,
          config: {
            edit,
          },
        },
        Orderedlist: {
          class: Orderedlist,
          config: {
            edit,
          },
        },
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            // Add a custom rendering function if necessary
            onRender: (block) => {
              // Retrieve and apply fontSize if it exists
              if (block.data.fontSize) {
                const blockElement = document.querySelector(
                  `.ce-block[data-id="${block.id}"] .ce-paragraph`
                );
                if (blockElement) {
                  (blockElement as HTMLElement).style.fontSize =
                    block.data.fontSize;
                }
              }
            },
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 3,
            defaultAlignment: 'left',
          },
        },
        // quote: {
        //   class: Quote,
        //   inlineToolbar: true,
        //   shortcut: 'CMD+SHIFT+O',
        //   config: {
        //     quotePlaceholder: 'Enter a quote',
        //     captionPlaceholder: "Quote's author",
        //   },
        // },
        // warning: {
        //   class: Warning,
        //   inlineToolbar: true,
        //   shortcut: 'CMD+SHIFT+W',
        //   config: {
        //     titlePlaceholder: 'Title',
        //     messagePlaceholder: 'Message',
        //   },
        // },
        // delimiter: Delimiter,
        // alert: {
        //   class: Alert,
        //   inlineToolbar: true,
        //   shortcut: 'CMD+SHIFT+A',
        //   config: {
        //     alertTypes: [
        //       'primary',
        //       'secondary',
        //       'info',
        //       'success',
        //       'warning',
        //       'danger',
        //       'light',
        //       'dark',
        //     ],
        //     defaultType: 'primary',
        //     messagePlaceholder: 'Enter something',
        //   },
        // },
        // toggle: {
        //   class: ToggleBlock,
        //   inlineToolbar: true,
        // },
        // linkTool: {
        //   class: LinkTool,
        //   config: {
        //     endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching,
        //   },
        // },
        // Marker: {
        //   class: ColorPlugin,
        //   config: {
        //     defaultColor: '#FFBF00',
        //     type: 'marker',
        //     icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
        //   },
        //   shortcut: 'CMD+SHIFT+M',
        // },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        underline: Underline,
        strikethrough: Strikethrough,
        // image: {
        //   class: ImageTool,
        //   config: {
        //     endpoints: {
        //       byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
        //       byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
        //     },
        //   },
        // },
      },
    });
    editorRef.current = editor;
  };

  const initEditorSession = () => {
    const editor = new EditorJS({
      holder: 'editorSession',
      placeholder: '',
      autofocus: false,
      onReady: () => {
        new DragDrop(editor);
      },
      data: {
        time: 1724934371391,
        version: '2.30.5',
        blocks: JSON.parse(state?.blocks),
      },
      tools: {
        Multiple_choice: MultiResponse,
        Single_choice: SingleResponse,
        LineaireScale: LineaireResponse,
        DropDown: DropDownResponse,
        Date: DateResponse,
        DateRange: DateRangeResponse,
        Short_answer: ShortResponse,
        Long_answer: LongResponse,
        Email: EmailResponse,
        Phone: PhoneResponse,
        Address: AdressResponse,
        Signature: SignatureResponse,
      },
    });
    editorRef.current = editor;
  };
  return (
    <EditorContext.Provider
      value={{
        initEditor,
        editorRef,
        selectedTemplate: state.selectedTemplate,
        handleSelectedTemplate,
        handleCloseTemplate,
        addBlock,
        setBlocks,
        undoRef,
        setActiveStyle,
        ...state,
        toggleSignature,
        handleChangeText,
        toggleEditMode,
        resetBlock,
        startPosition,
        endPosition,
        initEditorSession,
        clearBlock,
        togglePreview,
        setBlockId,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditorContext must be used within a editorProvider');
  }
  return context;
}
