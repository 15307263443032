import useCreateQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateQuestionnaire';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import { QUESTIONNAIRE_BY_FILTER } from './Questionaires';
import useCreateQuestionnairebyPatient from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateQuestionnaireByPatient';

function useAssignQuestionaire(isOne = true) {
  const [handleCreateQuestionnaire] = useCreateQuestionnaire();
  const [handleCreateQuestionnaireByPatient, { loading }] =
    useCreateQuestionnairebyPatient();

  const handleAssignQuestionaire = async (
    questions: IQuestion[],
    doctorId: string,
    patientId: string,
    title: string,
    description: string,
    formType?: string,
    blocks?: string,
    patients?: [string]
  ) => {
    const questionPayload = questions.map(
      ({
        question,
        type,
        options,
        description,
        required,
        range,
        isDescription,
      }) => {
        return {
          question,
          type,
          options,
          description,
          required,
          range,
          isDescription,
        };
      }
    );

    const payload = {
      title,
      description,
      questions: questionPayload,
      assignedAt: new Date(),
      doctor: doctorId,
      patient: patientId,
      formType,
      blocks,
    };
    if (isOne) {
      await handleCreateQuestionnaire({
        variables: { questionnaireInput: payload },
        refetchQueries: [
          {
            query: QUESTIONNAIRE_BY_FILTER,
            variables: { patientId, page: '1', limit: '5' },
          },
        ],
      });
    } else {
      await handleCreateQuestionnaireByPatient({
        variables: { questionnaireInput: payload, patients },
        refetchQueries: [
          {
            query: QUESTIONNAIRE_BY_FILTER,
            variables: { patientId, page: '1', limit: '5' },
          },
        ],
      });
    }
  };
  return { handleAssignQuestionaire, loading };
}

export default useAssignQuestionaire;
